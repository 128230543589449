<template>
  <div style="background-color: #f5faff;z-index: 0;height: 100vh">
    <NavBar style="max-height=50px;margin-bottom: 10px;" :username="currentUserName"></NavBar>
    <v-app style="background-color: #f5faff;" aligncenter>
      <div style="background-color: #f5faff;">
        <v-row>
          <v-col cols="2">

          </v-col>
          <v-col cols="10">
            <template>
               <v-tabs v-model="tab" align-with-title>
                <v-tab v-for="item in tab_list" :key="item.tab">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
          </v-col>
          <v-col cols="10">
            <v-tabs-items v-model="tab" style="background-color: #f5faff;">
              <v-tab-item  style="overflow: scroll; height: 80vh; background: red;">
                <v-card style="padding: 32px 24px;">
                  <v-row>
                    <v-col cols="12">
                      <div style="display: flex; flex-direction: row; margin-top: 10px;">
                        <div>
                         Booking ID:
                        </div>
                        <div align="left" style="width: 50%;">
                         <span style="padding-left: 1%; font-weight: 600;">{{appointment_reference_number}}</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div id="form" style="width:95%;">
                        <fieldset class="fieldset_class" style="padding:15px">
                          <legend align="left" style="font-weight:bold;">Covid Related Symptoms</legend>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                              v-model="selected"
                              label="Fever"
                              value="Fever"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Shortness of breath"
                              value="Shortness of breath"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Flu like illness(Sneezing, cough)"
                              value="Flu like illness(Sneezing, cough)"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                              v-model="selected"
                              label="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                              value="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Contact with a person who had travelled to COVID-19 suspected areas"
                              value="Contact with a person who had travelled to COVID-19 suspected areas"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="None of the Above"
                              value="None of the Above"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                              v-model="selected"
                              label="Loss of smell"
                              value="No Smell"
                              ></v-checkbox>
                              <v-checkbox
                              v-model="selected"
                              label="Travelling history within 2 weeks"
                              value="Travelling history within 2 weeks"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </fieldset>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row style="width: 95%; margin-top: 10px; margin-left: 10px; padding:15px;">
                    <v-col cols="10" sm="5" md="4" align="left">
                      Allergies
                      <v-textarea class="textarea_class" v-model="allergies" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="4" align="left">
                      Diagnosed With
                      <v-textarea class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="4" align="left">
                      Current Taking Medicines
                      <v-textarea class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row  style="width: 95%; margin-top: 10px;margin-left: 10px;">
                    <v-col cols="6" sm="6" md="6" align="left">
                      Prescription
                    </v-col>
                    <v-col cols="12" sm="12" md="12" align="left">
                      <table style=" width: 100%; background-color: #F1F6FF;">
                        <tr align="left" style="padding: 15px;">
                          <td style="font-size:16px; line-height: 20px;">Medicine</td>
                          <td style="font-size:16px; line-height: 20px;">Unit(Tablet/Syrup)</td>
                          <td style="font-size:16px; line-height: 20px;">Dosage</td>
                        </tr>
                        <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                          <td><v-text-field class="textfield_class" solo
                           v-model="item.medicine"/></td>
                          <td><v-text-field class="textfield_class" solo
                           v-model="item.unit"/></td>
                          <td><v-text-field class="textfield_class" solo
                           v-model="item.dosage"/></td>
                          <td><img v-if="index == prescription_object.length-1" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                            <img v-else-if="index < prescription_object.length" src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.unit,item.dosage)"></td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row style="width: 95%; margin-top: 10px;margin-left: 10px">
                    <v-col cols="10" sm="5" md="6" align="left">
                      Suggestions (If Any)
                      <v-textarea class="textarea_class" v-model="suggestions" filled solo rows=1></v-textarea>
                    </v-col>
                    <v-col cols="10" sm="5" md="6" align="left">
                      Examinations to be done (If Any)
                      <v-textarea class="textarea_class" v-model="exams" filled solo rows=1></v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row  style="width: 95%; margin-top: 10px;">
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="need_follow_up"
                      label="Need Follow up appointment"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="referred_to_specialist"
                      label="Referred to a specialist"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="patient_no_show"
                      label="Patient No Show"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-checkbox
                      v-model="unstable_connectivity"
                      label="Unstable Connectivity"
                      value="true"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn color = primary @click="save_prescription"> Update Prescription </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>

             <v-tab-item>
              <div>
                <div
                  width="99%"
                  height="60vh"
                  style="
                    margin-right: 2%;
                    border-radius: 12px;
                    overflow: scroll;
                    height: 60vh;
                  "
                >
                  <div
                    style="display: flex; flex-dirextion: row; flex-wrap: wrap"
                  >
                    <v-card
                      width="20vw"
                      style="border-radius: 12px; margin: 2%"
                      v-for="prescription in prescriptionDetails"
                      :key="prescription.appointment_reference_number"
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          justify-content: left;
                          margin-top: 15px !important;
                        "
                      >
                        <div>
                          <h4
                            align="left"
                            style="padding-top: 10px; margin-left: 20px"
                            v-if="prescription.diagnosed_with != ''"
                          >
                            {{ prescription.diagnosed_with }}
                          </h4>

                          <h4
                            align="left"
                            style="padding-top: 10px; margin-left: 20px"
                            v-else
                          >
                            NA
                          </h4>
                        </div>

                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              padding: 2px;
                              margin-top: 5%;
                              margin-left: 5%;
                            "
                          >
                            <div>
                              <h6
                                style="
                                  margin-top: 20%;
                                  color: #828282 !important;
                                "
                                align="left"
                              >
                                Doctor Name
                              </h6>
                            </div>
                            <div>
                              <p
                                style="margin-top: 2px; font-weight: 700"
                                align="left"
                              >
                                {{ prescription.doctor_name }}
                              </p>
                            </div>
                          </div>

                          <div>
                            <div
                              style="
                                display: flex;
                                flex-direction: row;
                                margin-top: 35%;
                                margin-right: 10px;
                              "
                            >
                              <div>
                                <img
                                  style="width: 25px; height: 25px"
                                  src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                                />
                              </div>
                              <div style="margin-top: 3px; margin-left: 5px">
                                <strong>{{
                                  prescription.date_of_appointment
                                }}</strong>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style="margin-top: 10px; margin-bottom: 10px">
                          <v-btn
                            color="primary"
                            width="85%"
                            @click="viewPrescription(prescription._id)"
                            style="border-radius: 8px; bottom: 10px"
                          >
                            View Prescription
                          </v-btn>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>

<script>
  import axios from "axios";
  import NavBar from '../../../views/navbar.vue';
  export default {
    name: 'DoctorPrescription',
    components: {
      NavBar
    },
    data() {
      return {
        appointment_reference_number : "",
        currentUser:"",
        currentUserName : "",
        doctor_id: "",
        selected: [],
        allergies: "",
        diagnosed_with: "",
        current_taking_medicines: "",
        med1: "",
        med2: "",
        med3: "",
        unit1: "",
        unit2: "",
        unit3: "",
        dosage1: "",
        dosage2: "",
        dosage3: "",
        suggestions: "",
        exams: "",
        referred_to_specialist: "false",
        need_follow_up: "false",
        patient_no_show: "false",
        unstable_connectivity: "false",
        customer_name: "",
        customer_id: "",
        date_of_appointment: "",
        specialization: "",
        isDisabled: true,
        tab: null,
        tab_list: [{tab:'New Prescription',content: 'New Prescription'},{tab:'Old Prescription',content: 'Prescription History'}],
        prescription_object: [{'medicine': '', 'unit': '', 'dosage': ''}],
        prescriptionDetails:"",
        prescriptionId:"",
      }
    },
    methods: {
      add_rec(){
        this.prescription_object.push({'medicine': '', 'unit': '', 'dosage': ''})
      },
      remove_rec(medicine,unit,dosage)
      {
        this.prescription_object.forEach(function(item, index, object) {
          if (item.medicine === medicine && item.unit === unit && item.dosage === dosage) {
            object.splice(index, 1);
          }
        });
      },
      viewPrescription(prescriptionID) {
      this.prescriptionId = prescriptionID;
      let routeData = this.$router.push({path: "/doctor/viewPrescription/"+ prescriptionID});
    },
    save_prescription() {
      var presc_body = {
        covidRelatedSymptoms: this.selected,
        allergies: this.allergies,
        diagnosedWith: this.diagnosed_with,
        currentMedication: this.current_taking_medicines,
        suggestion: this.suggestions,
        examinationTBD: this.exams,
        followUp: this.need_follow_up,
        patientNoShow: this.patient_no_show,
        referredSpecialist: this.referred_to_specialist,
        unstableConnection: this.unstable_connectivity,
        appointmentReferenceNo: this.appointment_reference_number,
        doctorName: this.currentUserName,
        doctorId: this.doctor_id,
        customerName: this.customer_name,
        customerId: this.customer_id,
        appointmentDate: this.date_of_appointment,
        specialization: this.specialization,
        token : this.currentUser,
        typeOfUser : "DOCTOR"
      }
      this.prescription_object.forEach(function(item, index, object) {
        if (item.medicine === '') {
          object.splice(index, 1);
        }
      });
      if(this.prescription_object.length == 0)
        presc_body.prescription = [];
      else if(this.prescription_object.length > 0)
        presc_body.prescription = this.prescription_object;
      axios.post(process.env.VUE_APP_BACKEND_URL+"/createPrescription",presc_body)
      .then((createPrescriptionResponse) =>{
        window.alert("Prescription Saved");
      })
      .catch((createPrescriptionError) =>{
      });
    }
  },
  mounted(){
    this.currentUser = this.$cookies.get("doctorToken");
    if(!this.currentUser){
      this.$router.push({
        name: "DoctorLogin",
      });
    }
    else{
      this.bookingId = this.$route.params.prescription_id.split("_")[0];
      var data = {
        token : this.currentUser,
        typeOfUser : "DOCTOR",
        bookingId : this.bookingId,
        page: 1,
        limit: 8
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/doctorPrescriptionPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.doctor_data.doctor_name;
        this.doctor_id = successResponse.data.doctor_data._id;
        this.customer_id = successResponse.data.appointment_data.booked_for_id;
        this.customer_name = successResponse.data.appointment_data.booked_for_name;
        this.date_of_appointment = successResponse.data.appointment_data.date_of_appointment;
        this.specialization = successResponse.data.appointment_data.specialization_name;
        this.prescriptionDetails = successResponse.data.prescription_data.docs;
        if(successResponse.data.appointment_prescription_data.length > 0)
        {
          var presc_dets = successResponse.data.appointment_prescription_data[0];
          if(presc_dets.hasOwnProperty('covid_related_symptoms'))
          {
            this.selected = presc_dets['covid_related_symptoms'];
          }
          if(presc_dets.hasOwnProperty('allergies'))
          {
            this.allergies = presc_dets['allergies'];
          }
          if(presc_dets.hasOwnProperty('diagnosed_with'))
          {
            this.diagnosed_with = presc_dets['diagnosed_with'];
          }
          if(presc_dets.hasOwnProperty('current_taking_medicines'))
          {
            this.current_taking_medicines = presc_dets['current_taking_medicines'];
          }
          if(presc_dets.hasOwnProperty('suggestion'))
          {
            this.suggestions = presc_dets['suggestion'];
          }
          if(presc_dets.hasOwnProperty('allergies'))
          {
            this.allergies = presc_dets['allergies'];
          }
          if(presc_dets.hasOwnProperty('need_follow_up'))
          {
            if(presc_dets['need_follow_up'] == true)
              this.need_follow_up = "true"
          }
          if(presc_dets.hasOwnProperty('examination_to_be_done'))
          {
            this.exams = presc_dets['examination_to_be_done'];
          }
          if(presc_dets.hasOwnProperty('patient_no_show'))
          {
            if(presc_dets['patient_no_show'] == true)
              this.patient_no_show = presc_dets['patient_no_show'];
          }
          if(presc_dets.hasOwnProperty('referred_to_specialist'))
          {
            if(presc_dets['referred_to_specialist'] == true)
            this.referred_to_specialist = "true";
          }
          if(presc_dets.hasOwnProperty('unstable_connectivity'))
          {
            if(presc_dets['unstable_connectivity'] == true)
            {
              this.unstable_connectivity = "true";
            }
          }
          if(presc_dets.hasOwnProperty('prescription'))
          {
            if(presc_dets['prescription'].length > 0)
              this.prescription_object = presc_dets['prescription'];
          }
        }
      })
      .catch((errorResponse) => {
        if(errorResponse.response.status == 401){
          window.alert("Sorry !! You are UNAUTHORIZED");
          this.$router.push({
            name : "DoctorLogin"
          });
          }
        else if(errorResponse.response.status == 400){
          window.alert("Something went wrong, please try again later");
          this.$router.push({
            name: "DoctorHome"
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.fieldset_class
  {
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: none;
  }
  .textfield_class {
    box-sizing: border-box;
    border-radius: 8px;
  }
</style>